module.exports = [{
      plugin: require('../../../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M82HZ3T","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"insify-group"}},"routeChangeEventName":"route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Insify group","short_name":"Insify","start_url":"/","background_color":"#030621","theme_color":"#030621","display":"minimal-ui","icon":"src/assets/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ae834e1f7d4d9432baad035dacc8885b"},
    },{
      plugin: require('../../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
